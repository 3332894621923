import React from 'react';
import { Link } from 'gatsby';

const Button = props => (
  <Link to={props.link} aria-label={props.aria_label}>
    <button className={props.className}>{props.text}</button>
  </Link>
);

export default Button;
