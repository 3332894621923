import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Button from './Button';

const CallToAction = () => (
  <StaticQuery
    query={graphql`
      {
        file(name: { eq: "ridge_runner-103" }) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.file.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag='section'
          className='bg-primary md:py-24 relative'
          fluid={imageData}>
          <div className='bg-black opacity-75 absolute pin' />
          <div className='container text-center relative'>
            <div className='flex flex-row justify-center'>
              <div className='w-full md:w-2/3'>
                <h2 className='mb-6 uppercase title'>
                  Let's discuss your <br />
                  pipeline commissioning needs
                </h2>
                <Button
                  text='Contact Us'
                  className='button inverted'
                  link='/contact'
                />
              </div>
            </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

export default CallToAction;
