import React from 'react';
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Button from '../components/Button';
import CallToAction from '../components/CallToAction';

const Rentals = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicEquipmentRentalsPage.data.meta_title}
      meta_description={data.prismicEquipmentRentalsPage.data.meta_description}
    />
    <Hero
      title={data.prismicEquipmentRentalsPage.data.hero_text.text}
      subheading={data.prismicEquipmentRentalsPage.data.subheading.text}
    />

    <section className='p-0 bg-black'>
      <div className='flex flex-wrap'>
        {data.allPrismicEquipment.edges.map(({ node }) => (
          <BackgroundImage
            key={node.id}
            className='w-full md:w-1/2 text-center no-underline py-20 md:py-24 lg:py-32 bg-grey-darker relative'
            fluid={node.data.card_image.localFile.childImageSharp.fluid}>
            <div className='absolute pin z-0 bg-black opacity-50' />
            <div className='z-10 relative flex flex-wrap justify-center'>
              <h2 className='text-xl md:text-2xl lg:text-3xl mb-2 w-3/5'>
                {node.data.equipment_title.text}
              </h2>
              <div className='w-full'>
                <Button
                  text='Learn More'
                  className='button secondary small'
                  link={`${node.uid}`}
                />
              </div>
            </div>
          </BackgroundImage>
        ))}
      </div>
    </section>

    <CallToAction />
  </Layout>
);

export default Rentals;

export const query = graphql`
  query equipmentPage {
    prismicEquipmentRentalsPage {
      id
      data {
        hero_text {
          html
          text
        }
        subheading {
          text
        }
        meta_title
        meta_description
      }
    }
    allPrismicEquipment {
      edges {
        node {
          id
          uid
          data {
            equipment_title {
              html
              text
            }
            card_image {
              alt
              url
              localFile {
                id
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 80, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
